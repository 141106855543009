<template>
  <section class="mt-2">
    <table class="w-full border rounded-lg divide-y divide-gray-200 text-sm">
      <thead class="bg-gray-300">
        <tr>
          <th class="px-4 py-2 text-center">№</th>
          <th class="px-4 py-2 text-center">
            {{ lang === "mn" ? "Уулзалтын дугаар" : "Meeting Number" }}
          </th>
          <th class="px-4 py-2 text-center">Төлөв</th>
          <th class="px-4 py-2 text-center">Уулзалтын өдөр, цаг</th>
          <th class="px-4 py-2 text-center">Уулзах хүсэлт илгээсэн</th>
          <th class="px-4 py-2 text-center">Уулзах хүсэлт авсан</th>
          <th class="px-4 py-2 text-center">Уулзалтын өрөө</th>
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="(meeting, index) in scheduledMeetings"
          :key="meeting.id"
          class="border-b border-gray-300"
        >
          <td class="px-4 py-2 text-center">{{ index + 1 }}</td>
          <td class="px-4 py-2 text-center">
            {{ meeting.meeting_number }}
          </td>
          <td class="px-4 py-2 text-center">
            {{ meeting.meeting_status }}
          </td>
          <td class="px-4 py-2 text-center text-blue-500 font-bold">
            {{ new Date(meeting.meeting_date).toISOString().split("T")[0] }}
            {{ meeting.meeting_time.slice(0, 5) }}
          </td>
          <td class="px-4 py-2 text-center">
            {{ lang === "mn" ? meeting.sender_name : meeting.sender_name_en }}
          </td>
          <td class="px-4 py-2 text-center">
            {{
              lang === "mn" ? meeting.receiver_name : meeting.receiver_name_en
            }}
          </td>
          <td class="flex flex-col gap-2 px-4 py-2">
            <span>Өрөөний №: {{ meeting.room_number }}</span>
            <span>Багтаамж: {{ meeting.capacity }}</span>
            <img :src="meeting.room_pic" alt="room" class="w-32" />
          </td>
        </tr>
      </tbody>
    </table>
  </section>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";

export default {
  name: "YellowScheduledMeetings",
  props: {
    meetings: Array,
  },
  setup(props) {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const scheduledMeetings = ref(props.meetings);
    return { lang, scheduledMeetings };
  },
};
</script>
