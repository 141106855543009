import clientYellowInstance from "@/lib/yellowClientInstance";

export default {
  namespaced: true,
  state() {
    return {
      user: {},
    };
  },

  getters: {
    getUserInformation(state) {
      return state.user;
    },
  },

  mutations: {
    setUserInformation(state, payload) {
      state.user = payload;
    },
  },

  actions: {
    async fetchUserInformation({ commit }, payload) {
      try {
        const response = await clientYellowInstance.post(
          "/auth/login",
          payload
        );
        if (response.status === 200) {
          commit("setUserInformation", response.data.account);
          return response.data.user;
        }
      } catch (err) {
        return err;
      }
    },

    async fetchUserByToken({ commit }, token) {
      try {
        const response = await clientYellowInstance.get(
          `/get/user?token=${token}`
        );
        if (response.status === 200) {
          commit("setUserInformation", response.data.user);
          return response.data.user;
        }
      } catch (err) {
        console.error("Error fetching user by email:", err.message);
        return err;
      }
    },
  },
};
