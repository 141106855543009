<template>
  <div class="flex">
    <!-- Sidebar -->
    <nav class="sticky top-0 bg-white border-r w-[20vw]">
      <section class="h-screen overflow-y-scroll pb-5">
        <div v-for="(admin, aIndex) in adminRoutes" :key="aIndex" class="mb-5">
          <div class="flex justify-center items-center gap-1 p-2">
            <img v-if="admin.logo" :src="admin?.logo" alt="logo" class="w-12" />
            <h6
              class="text-sm text-center font-bold"
              :class="{
                'text-green': admin.id === 1,
                'text-[#00a375]': admin.id === 2,
                'text-[#CC4628]': admin.id === 3,
              }"
            >
              {{ admin.title }}
            </h6>
          </div>

          <ul class="space-y-3 p-3 text-sm text-nowrap">
            <li
              v-for="(route, index) in admin.routes"
              :key="index"
              class="space-y-1"
            >
              <div
                v-if="route.section_title"
                class="relative text-center mb-3"
                :class="index === 0 ? 'mt-0' : 'mt-6'"
              >
                <hr class="border-t border-gray-300" />
                <p
                  class="absolute top-[-8px] left-4 bg-white text-xs text-gray-500 px-1"
                >
                  {{ route.section_title }}
                </p>
              </div>

              <div v-for="(link, linkIndex) in route.links" :key="linkIndex">
                <div
                  @click="navigateToLink(link.to, admin.id)"
                  class="flex items-center justify-between rounded-md"
                  :class="{
                    'text-green hover:text-green':
                      admin.id === 1 && isRoute === `/admin${link.to}`,
                    'text-[#00a375] hover:text-[#00a375]':
                      admin.id === 2 && isRoute === `/admin/week${link.to}`,
                    'text-[#CC4628] hover:text-[#CC4628]':
                      admin.id === 3 && isRoute === `/admin/yellow${link.to}`,
                    'cursor-pointer': link.to !== null,
                  }"
                >
                  <div class="flex items-center gap-2">
                    <font-awesome-icon
                      :icon="`fa-solid fa-${link.icon}`"
                      class="w-5 text-sm"
                    />
                    <h6>{{ link.name }}</h6>
                  </div>

                  <font-awesome-icon
                    v-if="link?.children && link.children.length"
                    icon="fa-solid fa-chevron-down"
                  />
                </div>

                <!-- Sub-menu -->
                <ul
                  v-if="link.children && link.children.length"
                  class="pl-7 mt-2 space-y-1 overflow-hidden"
                >
                  <li
                    v-for="(child, childIndex) in link.children"
                    :key="childIndex"
                    class="hover:cursor-pointer"
                  >
                    <div
                      @click="navigateToLink(child?.to, admin.id)"
                      :class="{
                        'text-green hover:text-green':
                          admin.id === 1 && isRoute === `/admin${child.to}`,
                        'text-[#00a375] hover:text-[#00a375]':
                          admin.id === 2 &&
                          isRoute === `/admin/week${child.to}`,
                        'text-[#CC4628] hover:text-[#CC4628]':
                          admin.id === 3 &&
                          isRoute === `/admin/yellow${child.to}`,
                      }"
                    >
                      <font-awesome-icon
                        :icon="
                          isRoute === `/admin/week${child.to}` ||
                          isRoute === `/admin${child.to}` ||
                          isRoute === `/admin/yellow${child.to}`
                            ? 'fa-regular fa-circle-dot'
                            : 'fa-regular fa-circle'
                        "
                        class="mr-1"
                      />
                      {{ child.name }}
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </nav>

    <!-- Content Area -->
    <div class="w-[80vw]">
      <div
        class="sticky top-0 flex justify-between items-center w-full bg-white text-gray-500 pl-3 pr-5 py-2 border-b z-20"
      >
        <div class="flex items-center gap-5">
          <div v-if="account" class="flex items-center gap-2">
            <div
              class="flex justify-center items-center h-8 w-8 rounded-full shadow-sm shadow-blue-500"
            >
              <span>{{
                account.first_name ? account.first_name.charAt(0) : ""
              }}</span>
            </div>
            <div class="block text-sm leading-4">
              <p class="text-blue-500">{{ account.first_name }}</p>
              <p class="text-[0.7rem]">{{ account.position }}</p>
            </div>
          </div>
        </div>
        <button @click="logout()" class="cursor-pointer hover:text-blue-500">
          <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
        </button>
      </div>

      <div class="bg-gray-100 p-3 space-y-3 min-h-[95vh] text-sm">
        <slot></slot>
      </div>

      <!-- Footer -->
      <footer class="w-full bg-gray-200 font-bold text-center text-xs p-1">
        <a href="https://bowsys.mn" target="_blank" class="hover:underline">
          &copy; BOW LLC - 2024
        </a>
      </footer>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "AdminNavbar",
  setup() {
    const account = ref({});
    const router = useRouter();
    const route = useRoute();
    const isRoute = ref(route.path);

    watch(
      () => route.path,
      (newRoute) => {
        isRoute.value = newRoute;
      }
    );

    const logout = () => {
      try {
        Cookies.remove("token");
        router.push("/admin/login");
      } catch (err) {
        return err;
      }
    };

    onMounted(async () => {
      try {
        const res = await adminInstance.get("/get/account");
        if (res.status === 200) {
          account.value = res.data.account;
        }
      } catch (err) {
        return err;
      }
    });

    const navigateToLink = (to, id) => {
      if (to !== null) {
        if (id === 1) {
          router.push(`/admin${to}`);
        } else if (id === 2) {
          router.push(`/admin/week${to}`);
        } else {
          router.push(`/admin/yellow${to}`);
        }
      }
    };

    const adminRoutes = [
      {
        id: 1,
        logo: require("../../assets/images/miningMongolia/favicon.png"),
        title: "miningmongolia.mn",
        routes: [
          {
            section_title: "Вэб",
            links: [
              {
                name: "Хянах самбар",
                to: "/dashboard",
                icon: "chart-line",
              },
              {
                name: "Вэбсайтын тохиргоо",
                to: null,
                icon: "sliders",
                children: [
                  {
                    name: "Цэс тохируулах",
                    to: "/link",
                  },
                  {
                    name: "Сошиал холбоос",
                    to: "/social-links",
                  },
                ],
              },
            ],
          },
          {
            section_title: "Вэбийн хуудаснууд",
            links: [
              {
                name: "Нүүр хуудас",
                to: "/slides",
                icon: "home",
              },
              {
                name: "Ассоциацийн зөвлөл",
                to: "/about",
                icon: "users",
              },
              {
                name: "Мэдээ мэдээлэл",
                to: null,
                icon: "newspaper",
                children: [
                  {
                    name: "Мэдээ нэмэх",
                    to: "/add-news",
                  },
                  {
                    name: "Мэдээ засварлах",
                    to: "/update-news",
                  },
                ],
              },
              {
                name: "Гишүүнчлэл",
                to: "/membership",
                icon: "user",
              },
              {
                name: "Төсөл хөтөлбөр",
                to: null,
                icon: "list-check",
                children: [
                  {
                    name: "ХУУ - Аудитор",
                    to: "/auditor",
                    icon: "users",
                  },
                  {
                    name: "ХУУ - Файлууд",
                    to: "/document",
                    icon: "file-pdf",
                  },
                  {
                    name: "Уул уурхай 101 - Бичлэг",
                    to: "/video",
                    icon: "video",
                  },
                ],
              },
            ],
          },
          {
            section_title: "Хүсэлт, үнэлгээ",
            links: [
              {
                name: "Хэрэглэгчдийн хүсэлт",
                to: "/request-list",
                icon: "list-check",
              },
              {
                name: "ХУУ - Өөрийн үнэлгээ",
                to: null,
                icon: "scale-balanced",
                children: [
                  {
                    name: "Үнэлгээний асуултууд",
                    to: "/update-assessment",
                  },
                  {
                    name: "Үнэлгээний хариу",
                    to: "/assessment-result",
                  },
                  {
                    name: "Гишүүн байгууллага",
                    to: "/member-companies",
                  },
                ],
              },
              {
                name: "Гишүүн байгууллагууд",
                to: "/companies",
                icon: "building",
              },
            ],
          },
          {
            section_title: "Уул уурхайн бүтээгдэхүүн",
            links: [
              {
                name: "Арилжааны мэдээлэл",
                to: "/trade",
                icon: "arrow-trend-up",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        logo: require("../../assets/images/miningWeek/MWLogo/favicon.png"),
        title: "miningweek.mn",
        routes: [
          {
            section_title: "Вэб",
            links: [
              {
                name: "Вэбсайтын тохиргоо",
                to: null,
                icon: "sliders",
                children: [
                  {
                    name: "Сошиал холбоос",
                    to: "/social-link",
                  },
                  {
                    name: "Холбогдох мэдээлэл",
                    to: "/footer",
                  },
                ],
              },
            ],
          },
          {
            section_title: "Хэрэглэгчид",
            links: [
              {
                name: "Бүртгэл /хуучин бааз/",
                to: "/register",
                icon: "users",
              },
              {
                name: "Бүртгэл /шинэ бааз/",
                to: "/new-register",
                icon: "users",
              },
            ],
          },
          {
            section_title: "Арга хэмжээ",
            links: [
              {
                name: "Хөтөлбөрийн огноо",
                to: "/event-date",
                icon: "calendar-days",
              },
              {
                name: "Хөтөлбөр",
                to: "/agenda",
                icon: "list",
              },
            ],
          },
          {
            section_title: "Илтгэл",
            links: [
              {
                name: "Илтгэгчид",
                to: "/presenters",
                icon: "play",
              },
              {
                name: "Илтгэлүүд",
                to: "/presentation",
                icon: "list",
              },
              {
                name: "Зургийн цомог",
                to: "/gallery",
                icon: "photo-film",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        logo: require("../../assets/images/miningMongolia/favicon.png"),
        title: "ЛАВЛАХ САЙТ",
        routes: [
          {
            section_title: "Вэб",
            links: [
              {
                name: "Слайд баннер",
                to: "/banner",
                icon: "house",
              },
            ],
          },
          {
            section_title: "Хүсэлт",
            links: [
              {
                name: "Сайтад ирсэн хүсэлтүүд",
                to: "/request",
                icon: "bell",
              },
            ],
          },
          {
            section_title: "Мэдээлэл",
            links: [
              {
                name: "Лавлахын мэдээлэл",
                to: null,
                icon: "list",
                children: [
                  {
                    name: "Байгууллага",
                    to: "/list-company",
                  },
                  {
                    name: "Зөвлөх инженер",
                    to: "/list-consultant",
                  },
                ],
              },
              {
                name: "Шинээр нэмэх",
                to: "/add",
                icon: "user-plus",
              },
            ],
          },
          {
            section_title: "Ангилал",
            links: [
              {
                name: "Лавлахын ангиллууд",
                to: "/category",
                icon: "list",
              },
              {
                name: "Шинэ ангилал нэмэх",
                to: "/new-category",
                icon: "add",
              },
            ],
          },
        ],
      },
    ];

    return {
      isRoute,
      logout,
      account,
      adminRoutes,
      navigateToLink,
    };
  },
};
</script>
