import clientInstance from "@/lib/clientInstance";

export default {
  namespaced: true,
  state() {
    return {
      recentNews: [],
      socialLinks: [],
      aboutUs: [],
    };
  },

  getters: {
    getRecentNews(state) {
      return state.recentNews;
    },

    getSocialLinks(state) {
      return state.socialLinks;
    },

    getAboutUs(state) {
      return state.aboutUs;
    },
  },

  mutations: {
    setRecentNews(state, payload) {
      state.recentNews = payload;
    },

    setSocialLinks(state, payload) {
      state.socialLinks = payload;
    },

    setAboutUs(state, payload) {
      state.aboutUs = payload;
    },
  },

  actions: {
    async fetchRecentNews({ commit }, payload) {
      try {
        const response = await clientInstance.get(
          `/get/recent-news?lang=${payload}`
        );
        if (response.status === 200) {
          commit("setRecentNews", response.data.recentNews);
        }
      } catch (err) {
        return err;
      }
    },

    async fetchSocialLinks({ commit }) {
      try {
        const response = await clientInstance.get("/get/social-links");
        if (response.status === 200) {
          commit("setSocialLinks", response.data.socialLinks);
        }
      } catch (err) {
        return err;
      }
    },

    async fetchAboutUs({ commit }) {
      try {
        const response = await clientInstance.get("/get/about-us");
        if (response.status === 200) {
          commit("setAboutUs", response.data.about);
        }
      } catch (err) {
        return err;
      }
    },
  },
};
