<template>
  <div class="bg-white rounded min-h-[80vh]">
    <div class="flex justify-start p-3 border-b">
      <button
        @click="cancelEdit"
        class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600"
      >
        <font-awesome-icon icon="fa-solid fa-angles-left" class="mr-1" />
        Буцах
      </button>
    </div>

    <div class="p-3 space-y-5">
      <div class="flex items-center w-full gap-5">
        <label for="name" class="w-1/5 text-end"
          >Нэр /монголоор/:<span class="text-red-500">*</span></label
        >
        <input
          type="text"
          placeholder="Нэр"
          class="w-4/5 border rounded p-1.5"
          v-model="form.name"
        />
      </div>

      <div class="flex items-center w-full gap-5">
        <label for="name" class="w-1/5 text-end"
          >Нэр /англиар/:<span class="text-red-500">*</span></label
        >
        <input
          type="text"
          placeholder="Name"
          class="w-4/5 border rounded p-1.5"
          v-model="form.name_en"
        />
      </div>

      <div class="flex gap-5">
        <h4 class="w-1/5 text-end">
          Зураг:<span class="text-red-500">*</span>
        </h4>

        <div class="space-y-3 w-4/5">
          <div
            class="flex flex-col justify-center items-center w-full"
            :class="mainImage ? 'w-3/4' : 'h-40 w-40'"
          >
            <img
              :src="imageSrc"
              alt="image"
              :class="!mainImage ? 'w-14 h-14' : ''"
            />
            <span v-if="!mainImage" class="text-xs text-gray-500"
              >Зураг байхгүй байна</span
            >
          </div>
          <input
            id="name"
            name="name"
            type="file"
            class="w-full border border-gray-300 rounded p-2"
            @change="onImageChange"
            accept="image/jpeg, image/png"
          />
        </div>
      </div>

      <div class="flex items-center w-full gap-5">
        <label for="position" class="w-1/5 text-end"
          >Албан тушаал /монголоор/:<span class="text-red-500">*</span></label
        >
        <input
          id="position"
          name="position"
          type="text"
          placeholder="Албан тушаал"
          class="w-4/5 border rounded p-1.5"
          v-model="form.position"
        />
      </div>

      <div class="flex items-center w-full gap-5">
        <label for="position_en" class="w-1/5 text-end"
          >Албан тушаал /англиар/:<span class="text-red-500">*</span></label
        >
        <input
          id="position_en"
          name="position_en"
          type="text"
          placeholder="Position"
          class="w-4/5 border rounded p-1.5"
          v-model="form.position_en"
        />
      </div>

      <div class="flex items-center w-full gap-5">
        <label for="company" class="w-1/5 text-end"
          >Эрхэлдэг ажил /монголоор/:<span class="text-red-500">*</span></label
        >
        <input
          id="company"
          name="company"
          type="text"
          placeholder="Эрхэлдэг ажил"
          class="w-4/5 border rounded p-1.5"
          v-model="form.company"
        />
      </div>

      <div class="flex items-center w-full gap-5">
        <label for="company_en" class="w-1/5 text-end"
          >Эрхэлдэг ажил /англиар/:<span class="text-red-500">*</span></label
        >
        <input
          id="company_en"
          name="company_en"
          type="text"
          placeholder="Job"
          class="w-4/5 border rounded p-1.5"
          v-model="form.company_en"
        />
      </div>

      <div class="flex justify-center">
        <button
          @click="uploadOffice()"
          class="flex justify-center items-center gap-1 w-[10rem] text-white rounded py-2"
          :class="
            form.id
              ? 'bg-blue-500 hover:bg-blue-600'
              : 'bg-[#28a745] hover:bg-[#208537]'
          "
        >
          <font-awesome-icon
            :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-plus'"
          />
          {{ form.id ? "Хадгалах" : "Нэмэх" }}
        </button>
      </div>
    </div>
  </div>
  <SuccessModal v-if="success" :success="success" @close-alert="closeAlert" />
</template>

<script>
import adminInstance from "@/lib/adminInstance";
import { onMounted, ref } from "vue";
import SuccessModal from "@/components/ui/SuccessModal.vue";

export default {
  name: "MMEditCouncil",
  props: {
    data: Object,
  },
  components: { SuccessModal },
  setup(props, { emit }) {
    const form = ref({
      id: null,
      name: null,
      name_en: null,
      position: null,
      position_en: null,
      company: null,
      company_en: null,
    });

    const success = ref(null);

    // Main image
    const defaultImage = require("@/assets/svg/image-not-found-icon.svg");
    const imageSrc = ref(defaultImage);
    const mainImage = ref("");

    const onImageChange = (event) => {
      const file = event.target.files[0];
      if (file) {
        imageSrc.value = URL.createObjectURL(file);
        mainImage.value = file;
      } else {
        imageSrc.value = defaultImage;
        mainImage.value = null;
      }
    };

    onMounted(() => {
      if (props.data) {
        form.value.id = props.data.id;
        form.value.name = props.data.name;
        form.value.name_en = props.data.name_en;
        form.value.position = props.data.position;
        form.value.position_en = props.data.position_en;
        form.value.company = props.data.company;
        form.value.company_en = props.data.company_en;
        imageSrc.value = props.data.profile;
        mainImage.value = props.data.profile;
      }
    });

    const resetForm = () => {
      form.value = {
        id: null,
        name: null,
        name_en: null,
        position: null,
        position_en: null,
        company: null,
        company_en: null,
      };
      imageSrc.value = defaultImage;
      mainImage.value = null;
    };

    const isValid = () => {
      if (!form.value.name) return alert("Нэр оруулна уу");
      if (!form.value.position) return alert("Албан тушаалыг оруулна уу");
      if (!form.value.company) return alert("Эрхэлсэн ажлыг оруулна уу");
      if (!mainImage.value) return alert("Зураг оруулна уу");

      return true;
    };

    const uploadOffice = async () => {
      if (!isValid()) return;

      const formData = new FormData();
      formData.append("name", form.value.name);
      formData.append("name_en", form.value.name_en);
      formData.append("image", mainImage.value);
      formData.append("position", form.value.position);
      formData.append("position_en", form.value.position_en);
      formData.append("company", form.value.company);
      formData.append("company_en", form.value.company_en);
      formData.append("about_category", "Association");

      try {
        let res;
        if (form.value.id) {
          formData.append("id", form.value.id);
          res = await adminInstance.post("/update/about-us", formData);
        } else {
          res = await adminInstance.post("/insert/about-us", formData);
        }

        if (res.status === 200) {
          success.value = "Амжилттай шинэчлэгдлээ.";
          resetForm();

          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        alert(`Алдаа гарлаа: ${err}`);
      }
    };

    const closeAlert = () => {
      success.value = null;
    };

    const cancelEdit = () => {
      resetForm();
      emit("cancel-edit");
    };

    return {
      form,
      imageSrc,
      mainImage,
      onImageChange,
      uploadOffice,
      resetForm,
      closeAlert,
      success,
      cancelEdit,
    };
  },
};
</script>
