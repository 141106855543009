<template>
  <main class="flex justify-center bg-[#f7f7f7] min-h-[80vh] font-helvetica">
    <div class="w-full min-[1200px]:w-4/5 max-[1199px]:px-5 py-5 md:py-10">
      <h1
        class="font-bold uppercase text-[#09396C] text-lg sm:text-xl md:text-3xl mb-5"
      >
        {{
          lang === "mn"
            ? '"MINING WEEK 2025" уулзалт'
            : "'MINING WEEK 2025' meeting"
        }}
      </h1>

      <!-- user info -->
      <div class="flex justify-between items-center mb-10">
        <div class="flex gap-14">
          <div class="flex flex-col">
            <span>{{
              lang === "mn" ? "Байгууллага / Нэр:" : "Organization / Surname:"
            }}</span>
            <span class="text-gray-500">{{
              lang === "mn" ? user.name : user.name_en
            }}</span>
          </div>

          <div class="flex flex-col">
            <span>{{ lang === "mn" ? "Цахим шуудан:" : "E-mail:" }}</span>
            <span class="text-gray-500">{{ user.email }}</span>
          </div>

          <div class="flex flex-col">
            <span>{{ lang === "mn" ? "Төрөл:" : "Attendee type:" }}</span>
            <span class="text-gray-500">{{ user.user_type }}</span>
          </div>
        </div>

        <button class="text-sm hover:scale-110">
          {{ lang === "mn" ? "Гарах" : "Logout" }}
          <font-awesome-icon icon="fa-solid fa-power-off" />
        </button>
      </div>

      <!-- new meeting -->
      <button
        class="p-2 rounded border border-[#cc4628] text-[#cc4628] text-sm hover:bg-[#cc4628] hover:text-white group"
      >
        <font-awesome-icon
          icon="fa-solid fa-add"
          class="mr-1 transition-transform duration-300 group-hover:rotate-90"
        />
        {{ lang === "mn" ? "Шинээр уулзалт товлох" : "Schedule a new meeting" }}
      </button>

      <!-- list -->
      <section class="mt-5">
        <h3 class="text-black">
          {{ lang === "mn" ? "Уулзалтын жагсаалт" : "Scheduled meetings" }}
        </h3>

        <YellowScheduledMeetings
          v-if="meetings.length > 0 && !isLoading"
          :meetings="meetings"
        />

        <div
          v-else-if="meetings.length === 0 && !isLoading"
          class="flex justify-center items-center w-full h-[20vh] text-gray-500 text-sm"
        >
          {{
            lang === "mn"
              ? "Товлосон уулзалт байхгүй байна."
              : "No scheduled meetings."
          }}
        </div>

        <div v-else class="flex justify-center items-center w-full h-[20vh]">
          <BarLoading />
        </div>
      </section>
    </div>
  </main>
</template>

<script>
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import YellowScheduledMeetings from "@/containers/YellowPage/YellowScheduledMeetings.vue";
import BarLoading from "@/components/ui/BarLoading.vue";
import clientYellowInstance from "@/lib/yellowClientInstance";

export default {
  name: "YellowMeetingSchedule",
  components: {
    YellowScheduledMeetings,
    BarLoading,
  },
  props: ["token"],
  setup(props) {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const user = computed(() => store.getters["yellow/getUserInformation"]);
    const meetings = ref([]);
    const isLoading = ref(false);
    const error = ref(false);

    const fetchUser = async () => {
      if (!user.value || !user.value.email) {
        await store.dispatch("yellow/fetchUserByToken", props.token);
      }
    };

    const fetchMeetingsByUser = async () => {
      try {
        isLoading.value = true;
        const res = await clientYellowInstance.get(
          `/get/meetings-by-user?id=${user.value.id}`
        );
        if (res.status === 200 && res.data.meetings) {
          meetings.value = res.data.meetings;
        }
      } catch (err) {
        error.value = "Уулзалтын жагсаалтад алдаа гарлаа.";
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(async () => {
      await fetchUser();
      if (user.value) {
        fetchMeetingsByUser();
      }
    });

    return { lang, user, meetings, isLoading };
  },
};
</script>
