<template>
  <admin-navbar>
    <main v-if="isMode" class="space-y-5">
      <h1 class="text-lg font-bold">Хөндлөнгийн үнэлгээний аудиторууд</h1>
      <div class="bg-white rounded p-3 h-[80vh] overflow-y-scroll">
        <button
          @click="toggleMode()"
          class="bg-blue-500 p-2 text-white text-xs rounded hover:bg-blue-600 mb-3"
        >
          <font-awesome-icon icon="fa-solid fa-add" class="mr-1" />
          Аудитор нэмэх
        </button>

        <div v-if="auditors.length > 0 && !isLoading" class="text-xs">
          <table
            class="table-auto w-full border-collapse border border-gray-200"
          >
            <thead>
              <tr class="bg-gray-100">
                <th class="border border-gray-300 px-4 py-2">Үйлдэл</th>
                <th class="border border-gray-300 px-4 py-2">Нэр</th>
                <th class="border border-gray-300 px-4 py-2">Зураг</th>
                <th class="border border-gray-300 px-4 py-2">Албан тушаал</th>
                <th class="border border-gray-300 px-4 py-2">Эрхэлдэг ажил</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(auditor, index) in auditors" :key="index">
                <td class="border border-gray-300 px-4 py-2">
                  <div class="flex gap-2 justify-center">
                    <button
                      @click="editAuditor(auditor)"
                      class="bg-sky-500 text-white px-2 py-1 rounded"
                    >
                      <font-awesome-icon icon="fa-solid fa-pen" />
                    </button>
                    <button
                      @click="deleteAuditor(auditor.id, auditor.name)"
                      class="bg-red-500 text-white px-2 py-1 rounded"
                    >
                      <font-awesome-icon icon="fa-solid fa-trash" />
                    </button>
                  </div>
                </td>
                <td class="border border-gray-300 px-4 py-2 text-center">
                  {{ auditor.name }}
                </td>
                <td class="border border-gray-300 px-4 py-2 text-center">
                  <img
                    :src="auditor.profile"
                    alt="Profile"
                    class="w-20 h-20 object-contain mx-auto"
                  />
                </td>
                <td class="border border-gray-300 px-4 py-2 text-center">
                  {{ auditor.position }}
                </td>
                <td class="border border-gray-300 px-4 py-2 text-center">
                  {{ auditor.company }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          v-else-if="auditors.length === 0 && !isLoading"
          class="flex justify-center items-center"
        >
          Жагсаалт хоосон байна
        </div>

        <div v-else class="flex justify-center items-center">
          <spin-loading />
        </div>
      </div>
    </main>

    <main v-else>
      <MMEditAuditor :data="selectedData" @cancel-edit="setMode()" />
    </main>
  </admin-navbar>
</template>

<script>
import AdminNavbar from "@/components/admin/AdminNavbar.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import { computed, onMounted, ref } from "vue";
import MMEditAuditor from "@/containers/MiningMongolia/admin/AboutUs/MMEditAuditor.vue";
import { useStore } from "vuex";
import adminInstance from "@/lib/adminInstance";

export default {
  name: "MMAdminAuditors",
  components: {
    AdminNavbar,
    SpinLoading,
    MMEditAuditor,
  },
  setup() {
    const store = useStore();
    const isMode = ref(true);
    const selectedData = ref(null);

    const aboutUs = computed(() => store.getters["client/getAboutUs"]);
    const auditors = ref([]);
    const isLoading = ref(false);

    const fetchData = async () => {
      try {
        isLoading.value = true;
        await store.dispatch("client/fetchAboutUs");
        if (aboutUs.value) {
          auditors.value = aboutUs.value.filter(
            (item) => item.about_category === "Auditor"
          );
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchData();
    });

    const toggleMode = () => {
      isMode.value = !isMode.value;
      if (isMode.value) {
        selectedData.value = null;
      }
    };

    const editAuditor = (auditor) => {
      selectedData.value = auditor;
      toggleMode();
    };

    const deleteAuditor = async (id, name) => {
      if (!window.confirm(`${name}-г устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/about-us?id=${id}`);
        if (res.status === 200) {
          fetchData();
        }
      } catch (err) {
        return err;
      }
    };

    const setMode = async () => {
      isMode.value = !isMode.value;
      if (isMode.value) {
        selectedData.value = null;
      }

      await fetchData();
    };

    return {
      isMode,
      auditors,
      isLoading,
      selectedData,
      fetchData,
      toggleMode,
      editAuditor,
      deleteAuditor,
      setMode,
    };
  },
};
</script>
