<template>
  <main class="flex justify-center bg-[#f7f7f7] min-h-[80vh] font-helvetica">
    <div
      class="flex flex-col justify-center items-center w-full min-[1200px]:w-4/5 max-[1199px]:px-5 py-5 md:py-10"
    >
      <h1
        class="font-bold uppercase text-[#09396C] text-lg sm:text-xl md:text-3xl lg:text-4xl"
      >
        {{
          lang === "mn"
            ? '"MINING WEEK 2025" уулзалт'
            : "'MINING WEEK 2025' meeting"
        }}
      </h1>
      <p class="text-gray-700 md:text-gray-800 text-center max-sm:text-sm">
        {{
          lang === "mn"
            ? "‘MINING WEEK 2025’ олон улсын уул уурхайн үзэсгэлэнд оролцож буй байгууллагуудтай уулзалт товлох албан ёсны хэсэг."
            : "Official section for scheduling meetings with exhibitors participating in the ‘MINING WEEK 2025’ international mining expo."
        }}
      </p>
      <div
        class="flex flex-col items-center bg-white w-full md:w-1/2 lg:w-1/3 rounded p-5 shadow mt-10"
      >
        <h3 class="text-[#09396C] text-lg uppercase">
          {{ lang === "mn" ? "Нэвтрэх" : "Login" }}
        </h3>
        <p class="text-xs text-gray-500 text-center mb-5">
          {{
            lang === "mn"
              ? "Энэ хэсэгт зөвхөн бүртгэлтэй хэрэглэгч нэвтрэх боломжтой."
              : "This feature is available to registered users only."
          }}
          <router-link
            to="/yellow-page/contact"
            class="underline hover:text-blue-500"
            >{{ lang === "mn" ? "/Холбоо барих/" : "/Contact/" }}</router-link
          >
        </p>
        <p v-if="unregisteredMessage" class="text-sm text-red-500">
          {{ unregisteredMessage }}
        </p>
        <!-- google sign -->
        <div id="buttonDiv"></div>
        <div class="flex items-center gap-4 w-full my-5">
          <hr class="flex-grow border-t border-gray-300" />
          <span class="text-gray-500 text-sm">{{
            lang === "mn" ? "эсвэл" : "or"
          }}</span>
          <hr class="flex-grow border-t border-gray-300" />
        </div>
        <!-- with code -->
        <div class="relative w-full h-10 mb-3">
          <input
            id="email"
            name="email"
            type="email"
            class="peer w-full h-full bg-transparent text-black outline outline-0 focus:outline-0 transition-all border focus:border focus:border-t-transparent text-sm px-3 py-2.5 rounded-md focus:border-blue-500"
            :class="email ? 'border-t-transparent' : ''"
            placeholder=" "
            @keyup.enter="sendConfirmationCode()"
            v-model="email"
          />
          <label
            for="email"
            class="flex w-full h-full select-none pointer-events-none absolute left-0 truncate leading-tight peer-focus:leading-tight transition-all -top-1.5 peer-placeholder-shown:text-sm text-xs peer-focus:text-xs before:content[' '] before:block before:box-border before:w-2.5 before:h-1.5 before:mt-[6.5px] before:mr-1 peer-placeholder-shown:before:border-transparent before:rounded-tl-md before:border-t peer-focus:before:border-t before:border-l peer-focus:before:border-l before:pointer-events-none before:transition-all peer-disabled:before:border-transparent after:content[' '] after:block after:flex-grow after:box-border after:w-2.5 after:h-1.5 after:mt-[6.5px] after:ml-1 peer-placeholder-shown:after:border-transparent after:rounded-tr-md after:border-t peer-focus:after:border-t after:border-r peer-focus:after:border-r after:pointer-events-none after:transition-all peer-disabled:after:border-transparent peer-placeholder-shown:leading-[3.75] text-gray-500 peer-focus:text-blue-500 before:border-blue-gray-200 peer-focus:before:!border-blue-500 after:border-blue-gray-200 peer-focus:after:!border-blue-500"
          >
            {{
              lang === "mn"
                ? "Бүртгэлтэй и-мэйл хаяг"
                : "Registered e-mail address"
            }}
          </label>
        </div>

        <button
          @click="sendConfirmationCode"
          class="bg-blue-500 text-white p-2 rounded-xl text-sm group"
        >
          <font-awesome-icon
            icon="fa-solid fa-paper-plane"
            class="mr-1 transition-transform duration-300 group-hover:rotate-45"
          />
          {{
            lang === "mn"
              ? "Баталгаажуулах код илгээх"
              : "Send confirmation code"
          }}
        </button>
      </div>
    </div>
  </main>

  <ErrorModal v-if="error" :error="error" />
</template>

<script>
import clientYellowInstance from "@/lib/yellowClientInstance";
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import ErrorModal from "@/components/ui/ErrorModal.vue";

export default {
  name: "YellowMeetingLogin",
  components: {
    ErrorModal,
  },
  setup() {
    const store = useStore();
    const lang = computed(() => store.getters.getLanguage);

    const unregisteredMessage = ref(null);
    const error = ref(null);
    const router = useRouter();

    const handleCredentialResponse = async (response) => {
      try {
        const res = await store.dispatch("yellow/fetchUserInformation", {
          idToken: response.credential,
        });
        if (res) {
          const token = res.token;
          router.push(`/yellow-page/meeting/${token}`);
        }
      } catch (err) {
        if (err.status === 400) {
          unregisteredMessage.value = err.response.data.message;
        } else {
          error.value = err.response.data.message;
        }
      }
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id:
          "156801078246-rih4ur764sl7v3oqfqudomh1omkosv0s.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        { theme: "outline", size: "large" }
      );
      window.google.accounts.id.prompt();
    };

    onMounted(() => {
      initializeGoogleSignIn();
    });

    const email = ref(null);
    const sendConfirmationCode = async () => {
      try {
        const res = await clientYellowInstance.post(
          "/send/confirmation-code",
          email.value
        );
        if (res.status === 200) {
          console.log(res.data);
        }
      } catch (error) {
        error.value = "";
      }
    };

    return {
      lang,
      error,
      handleCredentialResponse,
      email,
      sendConfirmationCode,
      unregisteredMessage,
    };
  },
};
</script>
